/*import { findAll } from "../utils/dom";

const EditorJS = () => {

		const EditorSimples = findAll("[js-editor-simples]");

	  
		if (EditorSimples) {
				EditorSimples.forEach((editor) => {
						if (editor.innerText) {
								let Texto = JSON.parse(editor.innerText);
								let ResultText = '';
								let TotalParagraph = Texto.blocks.length

								for (let i = 0; i < TotalParagraph; i++) {
										if (TotalParagraph > 0) {
												ResultText += `${Texto.blocks[i].data.text} <br />`;
										}
										else {
												ResultText = `${Texto.blocks[i].data.text}`;
										}


								}

								editor.innerHTML = ResultText;
						}
						//editor.innerHTML = Texto.blocks[0].data.text;
				})
		}

		const EditorTags = findAll("[js-editor-tags]");


		if (EditorTags) {
				EditorTags.forEach((editor) => {
						if (editor.innerText) {
								let Texto = JSON.parse(editor.innerText);
								let ResultText = '';
								let TotalTag = Texto.blocks.length;

								for (let i = 0; i < TotalTag; i++) {
										const Tipo = Texto.blocks[i].type;
										console.log(Texto.blocks[i])
										if (Tipo == 'paragraph') {
												ResultText += `<p>${Texto.blocks[i].data.text}</p>`
										}
										if (Tipo == 'Header') {
												const Level = Texto.blocks[i].data.level
												ResultText += `<h${Level}>${Texto.blocks[i].data.text}</h${Level}>`;
										}

										if (Tipo == 'List') {
												ResultText += `<ul>`
												const Itens = Texto.blocks[i].data.items;

												for (let k of Itens) {
														ResultText += `<li>&bull; ${k}</li>`
												}
												ResultText += `</ul>`
										}

								}

								editor.innerHTML = ResultText;
						}
				})
		}


}

export default EditorJS;*/

import { findAll } from "../utils/dom";

const EditorJS = () => {

	const EditorSimples = findAll("[js-editor-simples]");

	/** Para adicionar texto no front de maneira simples */
	if (EditorSimples) {
		EditorSimples.forEach((editor) => {
			if (editor.innerText) {
				let ResultText = '';
				let TotalParagraph = Texto.blocks.length

				for (let i = 0; i < TotalParagraph; i++) {
					if (TotalParagraph > 0) {
						ResultText += `${Texto.blocks[i].data.text} <br />`;
					}
					else {
						ResultText = `${Texto.blocks[i].data.text}`;
					}


				}

				editor.innerHTML = ResultText;
			}
			//editor.innerHTML = Texto.blocks[0].data.text;
		})
	}


	function makerEditorTags(editor) {
		if (editor.innerText) {
			let Texto;

			try {
				Texto = JSON.parse(editor.innerText);
			} catch (error) {
				return;
			}
			let ResultText = '';
			let TotalTag = Texto.blocks.length;


			for (let i = 0; i < TotalTag; i++) {
				const Tipo = Texto.blocks[i].type;
				//console.log(Texto.blocks[i])

				switch (Tipo) {
					case 'paragraph':
						ResultText += `<p>${Texto.blocks[i].data.text}</p>`
						break;

					case 'Header':
						const Level = Texto.blocks[i].data.level
						ResultText += `<h${Level}>${Texto.blocks[i].data.text}</h${Level}>`;
						break;

					case 'List':
						ResultText += `<ul class='${Texto.blocks[i]?.data?.style}'>`
						const Itens = Texto.blocks[i].data.items;

						for (let k of Itens) {
							ResultText += `<li>${k}</li>`
						}
						ResultText += `</ul>`
						break;

					case 'Image':
						ResultText += `<img src='${Texto.blocks[i].data.file.url}' />`
						break;
				}



			}

			editor.innerHTML = ResultText;
		}
	}

	const EditorTags = findAll("[js-editor-tags]");
	if (EditorTags) {
		EditorTags.forEach((editor) => {
			makerEditorTags(editor);
		})
	}








	/** Função para rescreever os títulos com os títulos customizados (se necessário) */
	function checarTitulo() {
		const titulos = findAll('[js-custom-text]');
		console.log(titulos);
		if (!titulos) return;

		const arrayTitulos = new Array();
		titulos.forEach((titulo) => {
			const tituloCustomizado = titulo.getAttribute('js-custom-text');
			//const caracterExtra = titulo.getAttribute('js-custom-text-extra') ?? '';
			if (tituloCustomizado == ' ') return;
			titulo.innerText = tituloCustomizado;
			makerEditorTags(titulo);

		});

	}

	checarTitulo();
}

export default EditorJS;